var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-calendar overflow-hidden border" }, [
    _c(
      "div",
      { staticClass: "row no-gutters" },
      [
        _c(
          "div",
          {
            staticClass:
              "col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column",
            class: { show: _vm.isCalendarOverlaySidebarActive },
          },
          [
            _c("calendar-sidebar", {
              attrs: {
                "is-event-handler-sidebar-active":
                  _vm.isEventHandlerSidebarActive,
              },
              on: {
                "update:isEventHandlerSidebarActive": function ($event) {
                  _vm.isEventHandlerSidebarActive = $event
                },
                "update:is-event-handler-sidebar-active": function ($event) {
                  _vm.isEventHandlerSidebarActive = $event
                },
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col position-relative" }, [
          _c(
            "div",
            { staticClass: "card shadow-none border-0 mb-0 rounded-0" },
            [
              _c(
                "div",
                { staticClass: "card-body pb-0" },
                [
                  _c("full-calendar", {
                    ref: "refCalendar",
                    staticClass: "full-calendar",
                    attrs: { options: _vm.calendarOptions },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
        _c("div", {
          staticClass: "body-content-overlay",
          class: { show: _vm.isCalendarOverlaySidebarActive },
          on: {
            click: function ($event) {
              _vm.isCalendarOverlaySidebarActive = false
            },
          },
        }),
        _c("calendar-event-handler", {
          attrs: { event: _vm.event, "clear-event-data": _vm.clearEventData },
          on: {
            "remove-event": _vm.removeEvent,
            "add-event": _vm.addEvent,
            "update-event": _vm.updateEvent,
          },
          model: {
            value: _vm.isEventHandlerSidebarActive,
            callback: function ($$v) {
              _vm.isEventHandlerSidebarActive = $$v
            },
            expression: "isEventHandlerSidebarActive",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }