var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1",
    },
    [
      _c(
        "div",
        { staticClass: "p-2" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              attrs: {
                "aria-controls": "sidebar-add-new-event",
                "aria-expanded": String(_vm.isEventHandlerSidebarActive),
                variant: "primary",
                block: "",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("update:isEventHandlerSidebarActive", true)
                },
              },
            },
            [_vm._v(" Add Event ")]
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _vm._m(0),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "mb-1",
                  model: {
                    value: _vm.checkAll,
                    callback: function ($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll",
                  },
                },
                [_vm._v(" View All ")]
              ),
              _c(
                "b-form-group",
                [
                  _c(
                    "b-form-checkbox-group",
                    {
                      attrs: { name: "event-filter", stacked: "" },
                      model: {
                        value: _vm.selectedCalendars,
                        callback: function ($$v) {
                          _vm.selectedCalendars = $$v
                        },
                        expression: "selectedCalendars",
                      },
                    },
                    _vm._l(_vm.calendarOptions, function (item) {
                      return _c(
                        "b-form-checkbox",
                        {
                          key: item.label,
                          staticClass: "mb-1",
                          class: "custom-control-" + item.color,
                          attrs: { name: "event-filter", value: item.label },
                        },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-img", {
        attrs: {
          src: require("@/assets/images/pages/calendar-illustration.png"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "app-label section-label mb-1" }, [
      _c("span", { staticClass: "align-middle" }, [_vm._v("Calendars")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }